import { useContext, type ReactElement } from "react";
import { DialogContext, type DialogContextInterface } from "./DialogContextProvider";
export { DialogContextProvider } from "./DialogContextProvider";

export interface DialogComponentProps<Input = unknown, Output = never> {
    value: Input;
    onSubmit: (values: Output) => Promise<void>;
    onClose: () => void;
    onError: (error: Error) => void;
}

export interface DialogActions<T> {
    onSubmit?: (value: T) => void | Promise<void>;
    onCancel?: () => void;
    onFinally?: () => void;
}

export interface DialogUserInterface<Input> {
    title?: string;
    description?: string;
    disableAutoclose?: boolean;
    defaultInput?: Partial<Input>;
}

export type DialogComponentFunction<T extends DialogComponentProps> = {
    title?: string;
    description?: string;
    disableAutoclose?: boolean;
    (props: T): ReactElement;
};

type ExtractDialogInput<P> = P extends DialogComponentProps<infer T> ? T : never;
type ExtractDialogOutput<P> = P extends DialogComponentProps<unknown, infer T> ? T : never;
type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export function useDialogContext<P extends DialogUserInterface<ExtractDialogInput<T>>, T extends DialogComponentProps>(
    dialog: DialogComponentFunction<T>,
    opts: P
) {
    const context: DialogContextInterface = useContext(DialogContext);

    if (!context) {
        throw new Error("DialogContext not found");
    }

    return {
        show(
            value: PartiallyOptional<ExtractDialogInput<T>, keyof P["defaultInput"]>,
            actions?: DialogActions<ExtractDialogOutput<T>>
        ) {
            if (opts.defaultInput) {
                value = { ...opts.defaultInput, ...value };
            }

            const optCombined = {
                ...dialog,
                ...opts,
            };

            return context.open({ dialog, opts: optCombined, value, actions });
        },
    };
}
