import { Checkbox } from "@/components/ui/checkbox";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";

function CheckboxCell({ field }: NextPropsField) {
    return (
        <Checkbox
            checked={field.value}
            onBlur={field.onBlur}
            onCheckedChange={field.onChange}
            disabled={field.disabled}
        />
    );
}

export default {
    Component: CheckboxCell,
    name: "Checkbox",
    rules: {
        rank: ({ element }) => {
            if (element?.array) return -1;
            if (element?.type === "Boolean") return 3;
            return -1;
        },
    },
} satisfies ComponentRegister;
