/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ZodSchema, type ZodTypeDef } from "zod";
import { loadSecrets } from "./actions/load-secrets.js";
import { loadConfigFromServer } from "./actions/loadConfigFromServer.js";
import { TemplateSource } from "./TemplateSource.js";

export class LiveConfig {
    id: string | undefined;
    data: any;

    loading: Promise<void> | undefined;
    sections: Record<string | symbol, any> = {};
    certs: Promise<{ key: string; crt: string } | undefined>;

    /**
     * Create a new LiveConfig manager instance
     * @param application - filled for applciations only with the unique string to identify the application
     * @param options - also
     */
    constructor(application?: string) {
        this.id = application;
        this.certs = loadSecrets();
        this.loading = loadConfigFromServer(this);
    }

    /**
     * Mostly used in cloudflare workers to load the configuration from the environment (passed as request parameter)
     * @param env - the environment variables to load the configuration from
     */
    load(env: any) {
        if (!this.data && env?.["CL_TEMPLATE"]) {
            const TEMPLATE = env["CL_TEMPLATE"] as string;
            const source = new TemplateSource(TEMPLATE);
            const configuration = source.build(env);
            this.data = JSON.parse(configuration) as any;
        }
    }

    waitUntilLoaded() {
        return this.loading ?? Promise.resolve();
    }

    section<O, D extends ZodTypeDef, I>(
        section: string,
        schema: ZodSchema<O, D, I> /*opts?: { onChange?: (changed: O) => void }*/
    ): O {
        // this.loadFromConfigServer(section, zodToJsonSchema(schema));

        const getParsedSection = () => {
            const cached = this.sections[section];

            if (cached) {
                return cached;
            }

            const data = schema.parse(this.data?.[section] ?? {});
            this.sections[section] = data;

            return data;
        };

        return new Proxy(
            {},
            {
                get: (_target, prop) => {
                    const section = getParsedSection();
                    return section[prop];
                },
            }
        ) as unknown as O;
    }
}
