import { coerceTypeToUint8Array, coerceUint8ArrayToType, WrapTypemap, WrapTypename } from "./coerce.js";
import { convertBase64ToUint8Array, convertUint8ArrayToBase64 } from "./utils.js";

export const METHOD = "RSA-256-OAEP";

export interface RsaEncryptedData<ENC> {
    algorithm: "RSA-256-OAEP";
    ciphertext: ENC;
    type: WrapTypename;
}

export function encode(data: RsaEncryptedData<Uint8Array>): RsaEncryptedData<string> {
    return {
        algorithm: data.algorithm,
        ciphertext: convertUint8ArrayToBase64(data.ciphertext),
        type: data.type,
    };
}

export async function encrypt(data: WrapTypemap[WrapTypename], key: CryptoKey): Promise<RsaEncryptedData<Uint8Array>> {
    const encoded = coerceTypeToUint8Array(data);
    const encrypted = await crypto.subtle.encrypt({ name: "RSA-OAEP" }, key, encoded.data);

    return {
        algorithm: METHOD,
        ciphertext: new Uint8Array(encrypted),
        type: encoded.type,
    };
}

export async function decrypt(data: RsaEncryptedData<string | Uint8Array>, key: CryptoKey) {
    try {
        const ciphertext = convertBase64ToUint8Array(data.ciphertext);
        const decryptedContent = await crypto.subtle.decrypt({ name: "RSA-OAEP" }, key, ciphertext);
        return coerceUint8ArrayToType(new Uint8Array(decryptedContent), data.type);
    } catch {
        throw new Error(`[${METHOD}] Decrypt failed`);
    }
}
