/* eslint-disable @typescript-eslint/no-explicit-any */
import { SchemaHelper } from "@/components/project/SchemaHelper";
import { useCallback, useEffect, useState } from "react";

export function useSchemaHelper<S extends SchemaHelper, K extends keyof Pick<S, "fetchTypeHints">>(
    schema: S,
    operation: K,
    variables: Parameters<S[K]>[0]
) {
    const [error, setError] = useState();
    const [value, setValue] = useState<Awaited<ReturnType<S[K]>>>();
    const [loading, setLoading] = useState(true);

    const callbackMemoized = useCallback(() => {
        setError(undefined);
        setLoading(true);
        setValue(undefined);
        schema[operation](variables)
            .then((v) => setValue(v as any))
            .catch(setError)
            .finally(() => setLoading(false));
    }, [operation, schema, variables]);

    useEffect(() => {
        callbackMemoized();
    }, [callbackMemoized]);

    return { value, error, loading };
}
