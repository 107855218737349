import * as aes from "./aes-256-gcm.js";
import { WrapTypemap, WrapTypename } from "./coerce.js";
import * as pki from "./pki.js";
import * as rsa from "./rsa-256-oaep.js";
import * as transport from "./transport.js";

export interface WrappedEncryptedStorage {
    payload: Omit<aes.AesEncryptedData<string>, "key">;
    keys: Record<string, rsa.RsaEncryptedData<string>>;
}

export interface WrappedEncryptedTransit extends Omit<aes.AesEncryptedData<string>, "key"> {
    key: rsa.RsaEncryptedData<string>;
}

export async function encrypt(
    data: WrapTypemap[WrapTypename],
    recipients: string | pki.CLCertImport[]
): Promise<WrappedEncryptedStorage> {
    const encrypted = await aes.encrypt(data);

    const keyless = { ...aes.encode(encrypted), key: undefined };
    delete keyless.key;

    const keys = typeof recipients === "string" ? await pki.importPublicKeys(recipients) : recipients;

    const encryptedKeys = keys.map(async (key) => {
        const rsaWrap = await rsa.encrypt(encrypted.key, key.publicKey);
        return [key.id, rsa.encode(rsaWrap)] as const;
    });

    return {
        payload: keyless,
        keys: Object.fromEntries(await Promise.all(encryptedKeys)),
    };
}

export async function decrypt(data: WrappedEncryptedTransit | string, recipient: CryptoKey) {
    const transmittedData = typeof data === "string" ? transport.decode(data) : data;

    const key = (await rsa.decrypt(transmittedData.key, recipient)) as Uint8Array;

    return aes.decrypt({ ...transmittedData, key });
}
