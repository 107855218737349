const test = navigator.webdriver;

export function sendErrorReport(message: string): void;
export function sendErrorReport(message: string, error: Error): void;
export function sendErrorReport(error: Error): void;
export function sendErrorReport(message_or_error: string | Error, error?: Error): void {
    if (!test) {
        return;
    }

    if (typeof message_or_error === "string") {
        if (error) {
            message_or_error = error;
        } else {
            message_or_error = new Error(message_or_error);
        }
    }

    console.error(message_or_error);
}
