import type { ComponentRegister, NextProps } from "@/shared/forms";
import { Next } from "@/shared/forms/core/Next";

function GridCanvas(props: NextProps) {
    return (
        <div className="grid items-start gap-4 pt-4">
            {props.element.elements?.map((element, i) => (
                <Next
                    key={`element-${i}`}
                    control={props.control}
                    element={element}
                    canvas={[...props.canvas, "Grid"]}
                    schema={props.schema}
                />
            ))}
        </div>
    );
}

export default {
    name: "Grid",
    Component: GridCanvas,
    rules: {
        array: false,
        parents: ["", "Card"],
        rank: ({ canvas }) => {
            const last = canvas[canvas.length - 1];
            return ["Dialog", "Card", "Page"].includes(last) ? 1 : -1;
        },
    },
} satisfies ComponentRegister;
