import get from "lodash.get";
import set from "lodash.set";
import type { WrappedEncryptedStorage, WrappedEncryptedTransit } from "./wrap.js";

const TRANSFORMS: Record<string, { include: string[]; assume: Record<string, string>; separator: string } | undefined> =
    {
        V1: {
            include: ["ciphertext", "iv", "key.ciphertext", "tag", "type"],
            assume: {
                "key.algorithm": "RSA-256-OAEP",
                "key.type": "bytes",
                algorithm: "AES-256-GCM",
            },
            separator: ",",
        },
    };

const ACTIVE = "V1";

function encodeTransport(data: WrappedEncryptedTransit): string {
    const instructions = TRANSFORMS[ACTIVE];

    if (!instructions) {
        throw new Error(`Unknown mode: ${ACTIVE}`);
    }

    const fields = instructions.include.map((path) => {
        return get(data, path) as string;
    });

    return `ENC:${ACTIVE}[${fields.join(instructions.separator)}]`;
}

const MESSAGE = /^ENC:(?<mode>.*)\[(?<encoded>.*)\]$/ms;

export function decode(data: string): WrappedEncryptedTransit {
    const parsed = data.match(MESSAGE);

    if (!parsed || !parsed.groups) {
        throw new Error("Incorrect encoding");
    }

    const mode = parsed.groups.mode;
    const encoded = parsed.groups.encoded;

    const instructions = TRANSFORMS[mode];
    if (!instructions) {
        throw new Error(`Unknown mode: ${mode}`);
    }

    if (!encoded) {
        throw new Error("Incorrect encoding");
    }

    const vars = encoded.split(instructions.separator);

    if (vars.length !== instructions.include.length) {
        throw new Error("Incorrect encoding");
    }

    const final: Record<string, unknown> = {};

    // add included variables in correct places
    instructions.include.forEach((k, i) => {
        set(final, k, vars[i]);
    });

    Object.entries(instructions.assume).forEach(([k, v]) => {
        set(final, k, v);
    });

    return final as unknown as WrappedEncryptedTransit;
}

export function encode<T extends boolean>(
    data: WrappedEncryptedStorage,
    recipientId: string,
    stringify?: T
): T extends true ? string : WrappedEncryptedTransit {
    const response: WrappedEncryptedTransit = {
        ...data.payload,
        key: data.keys[recipientId],
    };

    if (stringify === true) {
        return encodeTransport(response) as T extends true ? string : WrappedEncryptedTransit;
    }
    return response as T extends true ? string : WrappedEncryptedTransit;
}
