import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import { useState } from "react";

// https://www.adamcollier.co.uk/posts/adding-codemirror-6-to-a-react-project
// CM on mobile and monaco on desktop?
// lighter weight than syntax highlight would be cool to load editor only when needed

function JSONCell({ field }: NextPropsField) {
    const [expanded, setExpanded] = useState(false);

    return (
        <Card className="relative">
            <pre className={cn("p-2", expanded ? "" : "overflow-clip h-32")}>
                {JSON.stringify(field.value, null, 2)}
            </pre>
            {!expanded && (
                <div
                    onClick={() => setExpanded(true)}
                    className="absolute border border-transparent rounded-md top-0 w-full h-full cursor-pointer bg-gradient-to-b from-card/0 to-card/100"
                ></div>
            )}
        </Card>
    );
}

export default {
    name: "JSONCell",
    Component: JSONCell,
    rules: {
        rank: ({ element }) => {
            if (element?.array || !element?.type) return -1;
            if (["JSON", "JSONObject"].includes(element.type)) return 2;
            return -1;
        },
    },
} satisfies ComponentRegister;
