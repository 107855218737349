import { ProjectSelector } from "@/components/PortalHeader/ProjectSelector";
import { TabNavigation } from "@/components/PortalHeader/TabNavigation";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { useBreakpoints } from "@/hooks/useBreakpoints";
import { useLogoutMutation } from "@/hooks/useLogoutMutation";
import { useMe } from "@/hooks/useMe";
import { usePortalNavigation } from "@/hooks/usePortalNavigation";
import { Link } from "@/router";
import { useWhitelabel } from "@/whitelabel/Whitelabel";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";

export function Header() {
    const { Logo } = useWhitelabel();
    const { t } = useTranslation("shared");
    const { desktop: isDesktop } = useBreakpoints();
    const handleLogout = useLogoutMutation();
    const me = useMe();
    const { account } = usePortalNavigation();

    return (
        <header className="border-b bg-background px-4 md:px-6">
            <nav className="flex flex-row items-end h-12">
                {!account && <Logo variant="header-logo-text" />}
                {account && (
                    <Link to="/" className="flex items-center gap-2 text-lg font-semibold md:text-base md:mr-3">
                        <Logo variant="header-logo" />
                    </Link>
                )}
                {isDesktop && (
                    <>
                        <div className="[&>a:last-child>span]:flex">
                            <ProjectSelector />
                        </div>
                        <div id="branch-portal" className="pl-16" />
                    </>
                )}
                {!isDesktop && (
                    <>
                        <div className="[&>a:last-child>span]:flex flex-grow">
                            <ProjectSelector />
                        </div>
                        <div id="branch-portal" />
                    </>
                )}
                <div className="flex-grow" />
                {isDesktop && (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="icon" className="rounded-full hover:bg-slate-300">
                                <Avatar className="h-8 w-8">
                                    {me?.profilePicture && <AvatarImage src={me?.profilePicture} />}
                                    <AvatarFallback>
                                        {me?.firstName?.substring(0, 1)}
                                        {me?.lastName?.substring(0, 1)}
                                    </AvatarFallback>
                                </Avatar>
                                <span className="sr-only">{t("action.togglemenu", "Toggle user menu")}</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="min-w-60">
                            <DropdownMenuLabel>{t("navigation.user.myaccount", "My account")}</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem asChild className="cursor-pointer">
                                <Link to="/-/settings/*" params={{ "*": "" }}>
                                    {t("navigation.user.settings", "Settings")}
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem asChild className="cursor-pointer">
                                <button onClick={handleLogout} className="w-full">
                                    {t("action.logout", "Logout")}
                                </button>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )}
                {!isDesktop && (
                    <Button variant="ghost" size="icon" className="rounded-full hover:bg-slate-300" asChild>
                        <Link to="/-/settings/*" params={{ "*": "" }}>
                            <Avatar className="h-8 w-8">
                                {me?.profilePicture && <AvatarImage src={me?.profilePicture} />}
                                <AvatarFallback>
                                    {me?.firstName?.substring(0, 1)}
                                    {me?.lastName?.substring(0, 1)}
                                </AvatarFallback>
                            </Avatar>
                        </Link>
                    </Button>
                )}
            </nav>
            <TabNavigation />
        </header>
    );
}
