import type { AuthContextInterface } from "@/components/AuthContext";
import { authExchange as authExchangeFactory } from "@urql/exchange-auth";
import { graphql } from "gql.tada";

const RefreshToken = graphql.persisted(
    "sha256:2b74e7822e71236594c6ee320f6d578a5c099fdda4ae34e42712949ec28ef979",
    graphql(`
        mutation RefreshLogin {
            refreshToken
        }
    `)
);

let refreshing: Promise<void> | undefined = undefined;

export const authExchange = (context: AuthContextInterface) =>
    authExchangeFactory(async (utils) => {
        let tokenHash: string | undefined = undefined;

        return {
            addAuthToOperation(operation) {
                tokenHash = context.getTokenDigest(true);
                if (!tokenHash) {
                    return operation;
                }
                return utils.appendHeaders(operation, {
                    Authorization: tokenHash,
                });
            },
            didAuthError(error) {
                const response = error.graphQLErrors.some((e) =>
                    ["UNAUTHENTICATED", "UNAUTHORIZED_FIELD_OR_TYPE", "PERMISSION_DENIED"].includes(
                        `${e.extensions?.code}`
                    )
                );
                // console.log("didAuthError", response);
                return response;
            },
            async refreshAuth() {
                async function executeRefresh() {
                    // In case the refresh was already dont in other window
                    const currentToken = context.getTokenDigest(true);

                    if (!currentToken) {
                        // console.log("Token not found, logout");
                        context.logout();
                        return;
                    }

                    if (currentToken !== tokenHash) {
                        tokenHash = currentToken;
                        // console.log("Token already refreshed");
                        return;
                    }

                    const result = await utils.mutate(
                        RefreshToken,
                        {},
                        {
                            url: "/graphql-new",
                            fetchOptions: {
                                headers: {
                                    Authorization: currentToken,
                                },
                            },
                        }
                    );
                    refreshing = undefined;

                    if (result.data?.refreshToken === false) {
                        console.log("Token incorrect, logout");
                        context.logout();
                    } else if (result.data?.refreshToken === true) {
                        tokenHash = context.getTokenDigest(true);
                        console.log("Token refreshed");
                    } else {
                        console.error("Token refresh failed");
                    }
                }

                if (refreshing) {
                    return refreshing;
                }
                refreshing = executeRefresh();
                return refreshing;
            },
        };
    });
