/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { useMutation as useMutationUrql, type AnyVariables, type DocumentInput } from "urql";

interface UseMutationDialogOptions {
    onError?: (error: Error) => void;
}

export function useMutation<Data = any, Variables extends AnyVariables = AnyVariables>(
    query: DocumentInput<Data, Variables>,
    opts: UseMutationDialogOptions = {}
) {
    const [data, callback] = useMutationUrql(query);

    const action = useCallback(
        async (variables: Variables) => {
            const response = await callback(variables);

            if (response.error) {
                if (opts.onError) {
                    opts.onError(response.error);
                } else {
                    throw response.error;
                }
            } else {
                return response.data;
            }
        },
        [callback, opts]
    );

    return [data, action] as const;
}
