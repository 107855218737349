/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import { Next } from "@/shared/forms/core/Next";
import { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";

function generateSlug(name: string) {
    if (!name) {
        return name;
    }

    return name
        .toLowerCase()
        .replace(/[^a-z0-9-_]/g, "-")
        .replace(/[-]+/g, "-")
        .replace(/-$/g, "");
}

function ShadowInput({ field, control, element, ...rest }: NextPropsField) {
    const config = element.options?.controller?.ShadowInput as {
        shadow: string[];
        transform: string;
    };

    const source = useWatch({
        control: control,
        name: config.shadow.join("."),
    });

    const [shadow, setShadow] = useState(generateSlug(source) === field.value || !field.value);

    useEffect(() => {
        if (!source || !shadow) {
            return;
        }

        field.onChange(generateSlug(source));
    }, [field, field.value, shadow, source]);

    const fieldDownstream = useMemo(() => {
        return {
            ...field,
            onChange: (v: any) => {
                setShadow(generateSlug(source) === v.target.value);
                field.onChange(v);
            },
        };
    }, [field, source]);

    const elementDownstream = useMemo(() => {
        return {
            ...element,
            options: {
                ...element.options,
                controller: {
                    ...element.options?.controller,
                    ShadowInput: undefined,
                },
            },
        };
    }, [element]);

    return <Next {...rest} element={elementDownstream} control={control} field={fieldDownstream} />;
}

export default {
    name: "ShadowInput",
    Component: ShadowInput,
    rules: {
        rank: ({ element }) => {
            return (element.options?.controller?.ShadowInput as any)?.shadow ? 100 : -1;
        },
    },
} satisfies ComponentRegister;
