import { useAuthContext } from "@/hooks/useAuthContext";
import { useMutation } from "@/hooks/useMutationDialog";
import { graphql } from "gql.tada";
import { useCallback } from "react";

const Logout = graphql.persisted(
    "sha256:d33294c28ffdaff741dcadc2dba75b4013cdc29cd5f9e8f61d3ac85d57868a04",
    graphql(`
        mutation Logout {
            logout
        }
    `)
);

export function useLogoutMutation() {
    const auth = useAuthContext();
    const [, logout] = useMutation(Logout);

    const handleLogout = useCallback(async () => {
        await logout({});
        auth.logout();
    }, [auth, logout]);

    return handleLogout;
}
