import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n.use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        supportedLngs: ["en", "et"],
        fallbackLng: "en",
        returnEmptyString: false,
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });

export default i18n;
