/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import { objectHash } from "@/shared/forms/core/utils";
import invariant from "invariant";

function EnumController({ field, element }: NextPropsField) {
    invariant(element.options?.oneOf, "EnumController requires options.oneOf to be defined");

    const options = element.options.oneOf.map((optionValue) => {
        const label = typeof optionValue === "string" ? optionValue : optionValue.label;
        const value = typeof optionValue === "string" ? optionValue : optionValue.value;
        const id = objectHash(value);
        return { id, label, value };
    });

    const selected = options.find((option) => option.id === objectHash(field.value));

    return (
        <Select
            value={selected?.id || ""}
            onValueChange={(v) => {
                const final = options.find((option) => option.id === v)?.value;
                field.onChange(final);
            }}
            disabled={field.disabled}
        >
            <SelectTrigger className="w-full">
                <SelectValue placeholder={selected?.label || ""} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {options.map(({ id, label }) => {
                        return (
                            <SelectItem value={id} key={id}>
                                {label}
                            </SelectItem>
                        );
                    })}
                </SelectGroup>
            </SelectContent>
        </Select>
    );
}

export default {
    name: "Enum",
    Component: EnumController,
    rules: {
        rank: ({ element }) => {
            return element?.options?.oneOf ? 10 : -1;
        },
    },
} satisfies ComponentRegister;
