import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useCachedUrqlRefresh } from "@/components/UrqlProviderContext/hooks/useCachedUrqlClient";
import { cn } from "@/lib/utils";
import { RefreshCw } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export function RefreshButton() {
    const { t } = useTranslation("shared");
    const handle = useCachedUrqlRefresh();
    const [spin, setSpin] = useState("");
    const spinner = useRef<ReturnType<typeof setTimeout>>();

    const handleRefresh = async () => {
        if (spinner.current) {
            return;
        }

        handle.stats(true);
        setSpin("animate-spin");
        handle.refresh();
        spinner.current = setTimeout(() => {
            const errors = Object.values(handle.stats()).reduce((acc, { errors }) => acc + errors, 0);
            setSpin(errors ? "text-destructive" : "");
            spinner.current = undefined;
        }, 1000);
    };

    useEffect(() => {
        return () => {
            if (spinner.current) {
                clearTimeout(spinner.current);
            }
        };
    }, []);

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        size="icon"
                        variant="outline"
                        className="h-10 w-10 rounded-[8px] touch:hidden z-10"
                        onClick={handleRefresh}
                    >
                        <RefreshCw className={cn("h-4 w-4 text-muted-foreground", spin)} />
                    </Button>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                    <p>{t("action.refresh", "Refresh data")}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}
