import { Client, Exchange, Operation } from "@urql/core";
import { pipe, tap } from "wonka";

export const makeRefreshQueriesExchange = () => {
    const watchedOperations = new Map<number, Operation>();
    const observedOperations = new Map<number, number>();

    let urqlClient: Client;

    // Refreshes all of the mounted queries
    function refresh() {
        if (urqlClient) {
            watchedOperations.forEach((op) => {
                urqlClient.reexecuteOperation(
                    urqlClient.createRequestOperation("query", op, {
                        ...op.context,
                        requestPolicy: "cache-and-network",
                    })
                );
            });
        }
    }

    const refreshExchange: Exchange =
        ({ client, forward }) =>
        (ops$) => {
            urqlClient = client;
            const processIncomingOperation = (op: Operation) => {
                if (op.kind === "query" && !observedOperations.has(op.key)) {
                    observedOperations.set(op.key, 1);
                    watchedOperations.set(op.key, op);
                }

                if (op.kind === "teardown" && observedOperations.has(op.key)) {
                    observedOperations.delete(op.key);
                    watchedOperations.delete(op.key);
                }
            };
            return forward(pipe(ops$, tap(processIncomingOperation)));
        };

    return { refreshExchange, refresh };
};
