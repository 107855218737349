/* eslint-disable @typescript-eslint/no-explicit-any */
import { SchemaHelper } from "@/components/project/SchemaHelper";
import { useSchemaHelper } from "@/components/project/useSchemaHelper";
import { AlertFromError } from "@/components/ui/addon/alert";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { dottedToField } from "@/shared/forms/core/utils";
import get from "lodash.get";
import { useQuery } from "urql";

interface EntitySearchProps {
    entity: string;
    schema: SchemaHelper;
    onSelect: (id: string) => void;
    id?: string;
}

export function EntitySearch({ entity, onSelect, schema, id }: EntitySearchProps) {
    const { value, loading } = useSchemaHelper(schema, "fetchTypeHints", entity);
    const selectedFields = value?.listFieldsMobile ?? [];
    const [data] = useQuery({
        query: `
            query ListEntity {
                response: ${entity} {
                    list(first: 10) {
                        id
                        ${selectedFields.map(dottedToField).join("\n") ?? ""}
                    }
                }
            }
        `,
        pause: loading,
    });

    if (loading || data.fetching) {
        return <div>Loading...</div>;
    }

    return (
        <div className="pt-6">
            <AlertFromError error={data.error} />
            {data.data && (
                <RadioGroup onValueChange={onSelect} value={id}>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead />
                                {selectedFields.map((field) => (
                                    <TableHead key={field}>{field}</TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.data.response?.list.map((item: any) => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        <RadioGroupItem value={item.id} />
                                    </TableCell>
                                    {selectedFields.map((field) => (
                                        <TableCell key={field}>{get(item, field)}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </RadioGroup>
            )}
        </div>
    );
}
