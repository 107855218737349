import { ProjectSelectorDialog } from "@/components/PortalHeader/ProjectSelector/ProjectSelectorDialog";
import { ProjectSelectorNarrow } from "@/components/PortalHeader/ProjectSelector/ProjectSelectorNarrow";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useBreakpoints } from "@/hooks/useBreakpoints";
import { useDataUserAccounts } from "@/hooks/useDataUserAccounts";
import { usePortalNavigation } from "@/hooks/usePortalNavigation";
import { Link } from "@/router";
import { ChevronDown } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export function ProjectSelector() {
    const { mobile } = useBreakpoints();
    const params = usePortalNavigation();
    const [accounts] = useDataUserAccounts();
    const [open, setOpen] = useState(false);
    const sad = useLocation();

    useEffect(() => {
        setOpen(false);
    }, [sad.pathname]);

    const account = accounts?.find((account) => account.lookup === params.account);
    const project = account?.projects?.find((project) => project.lookup === params.project);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            {!project && account && (
                <>
                    <PopoverTrigger asChild>
                        <Button variant="ghost" size="sm" className="space-x-2">
                            <span>{account.name}</span>
                            <ChevronDown className="h-5 w-5" />
                        </Button>
                    </PopoverTrigger>
                </>
            )}
            {project && account && (
                <>
                    <Button variant="ghost" size="sm" asChild>
                        <Link to="/:account" params={{ account: account.lookup }}>
                            {account.name}
                        </Link>
                    </Button>
                    <span className="text-muted-foreground">/</span>
                    <PopoverTrigger asChild>
                        <Button variant="ghost" size="sm" className="space-x-2">
                            <span>{project?.name}</span>
                            <ChevronDown className="h-5 w-5" />
                        </Button>
                    </PopoverTrigger>
                </>
            )}
            <PopoverContent className="w-[100vw] md:w-full p-0 min-h-64 flex">
                <>{mobile ? <ProjectSelectorNarrow /> : <ProjectSelectorDialog />}</>
            </PopoverContent>
        </Popover>
    );
}
