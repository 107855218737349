/* eslint-disable @typescript-eslint/no-unsafe-assignment */

interface FsSimple {
    readFile: (path: string) => Promise<{ toString: () => string }>;
}

export async function loadFsModule(mdl = "fs/promises"): Promise<FsSimple | undefined> {
    try {
        const { readFile } = await import(mdl);
        return { readFile };
    } catch {
        return Promise.resolve(undefined);
    }
}
