import { Separator } from "@/components/ui/separator";
import type { ComponentRegister, NextProps } from "@/shared/forms";
import { Next } from "@/shared/forms/core/Next";

function DividedCanvas(props: NextProps & { title?: string }) {
    const children = props.element.elements;
    if (!Array.isArray(children)) {
        return null;
    }

    return (
        <div className="grid items-start gap-4 pt-4">
            {children.map((element, i) => (
                <>
                    <Next
                        key={`element-${i}`}
                        control={props.control}
                        element={element}
                        canvas={[...props.canvas, "Divided"]}
                        schema={props.schema}
                    />
                    {i < children.length - 1 && <Separator />}
                </>
            ))}
        </div>
    );
}

export default {
    name: "Divided",
    Component: DividedCanvas,
    options: {
        title: {
            type: "string",
        },
    },
    rules: {
        rank: ({ element, canvas }) => {
            const last = canvas[canvas.length - 1];
            const children = new Set(element.elements?.map((element) => element.type) ?? []);

            if (last !== "Dialog") return -1;
            if (children.size !== 1) {
                return -1;
            }
            return children.has("Group") ? 2 : -1;
        },
    },
} satisfies ComponentRegister;
