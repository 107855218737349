import { SchemaHelper } from "@/components/project/SchemaHelper";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import invariant from "invariant";

function SchemaObjectProvider(props: NextPropsField) {
    invariant(props.schema instanceof SchemaHelper, "SchemaObjectProvider requires a SchemaHelper");
    const target = props.schema.getType(props.element.type);
    invariant(
        ["_SystemTypeInterface", "_SystemTypeUnion"].includes(target?.__typename),
        "SchemaObjectProvider requires needs to be _SystemTypeInterface or _SystemTypeUnion"
    );

    if (props.field.value?.__typename) {
        return <pre className="bg-red-500">{JSON.stringify(props.field.value, null, 2)}</pre>;
    }

    return <pre className="bg-red-500">{JSON.stringify(target, null, 2)}</pre>;
}

export default {
    name: "SchemaObjectProvider",
    Component: SchemaObjectProvider,
    rules: {
        rank: ({ element, schema }) => {
            if (element.array) return -1;
            if (schema instanceof SchemaHelper) {
                const red = schema.getType(element.type);
                if (red && ["_SystemTypeInterface", "_SystemTypeUnion"].includes(red.__typename)) {
                    return 10;
                }
            }
            return -1;
        },
    },
} satisfies ComponentRegister;
