import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function projectPath(type: "d" | "c" | "s") {
    switch (type) {
        case "d":
            return "/:account/d/:project";
        case "c":
            return "/:account/c/:project";
        case "s":
            return "/:account/s/:project";
    }
}
