/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FormElementDefinition } from "@/shared/forms";
import { md5 } from "js-md5";
import { type FieldValues, type RegisterOptions, type Validate } from "react-hook-form";

type HookFormValidationRules = Omit<
    RegisterOptions<FieldValues, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
>;

const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function deriveRules(element: FormElementDefinition): HookFormValidationRules | undefined {
    const validate = element.options?.validate ?? [];

    if (element.required) {
        // auto generate required validation
        const set = validate.find((v) => v.required);
        if (!set) {
            validate.push({ required: true });
        }
    }

    if (element.options?.oneOf) {
        // auto generate oneOf validation
        const set = validate.find((v) => v.oneOf);
        if (!set) {
            validate.push({ oneOf: true });
        }
    }

    const rules: HookFormValidationRules = {};
    const validationFuncitons: Validate<any, FieldValues>[] = [];

    // map rules to hook form rules
    validate.forEach((rule) => {
        if (rule.required) {
            rules.required = rule.message ?? rule.required ?? true;
        }
        if (rule.oneOf) {
            validationFuncitons.push((value) => {
                if (!element.options?.oneOf) {
                    return "Incorrect field configuration";
                }
                const passed = element.options.oneOf.some((option) => {
                    const expectedValue = typeof option === "object" ? option.value : option;
                    return objectHash(expectedValue) === objectHash(value);
                });
                if (!passed) {
                    return rule.message ?? true;
                }
            });
        }
        if (rule.pattern) {
            const regex = new RegExp(rule.pattern);
            validationFuncitons.push((value) => {
                if (!regex.test(value)) {
                    return rule.message ?? true;
                }
            });
        }
        if (rule.validate) {
            validationFuncitons.push(async (value) => {
                const result = await rule.validate?.(value);
                if (result) {
                    return result ?? rule.message;
                }
            });
        }
        if (rule.format === "email") {
            validationFuncitons.push((value) => {
                if (!validateEmail(value)) {
                    return rule.message ?? true;
                }
            });
        }
    });

    // combine a single validation function
    rules.validate = async (value, formValues) => {
        for (const validate of validationFuncitons) {
            const result = await validate(value, formValues);
            if (result) {
                return result;
            }
        }
    };

    return rules;
}

export function calculateFieldLabel(target: FormElementDefinition) {
    return `${target.label}${target.array ? "[]" : ""}${target.required ? "*" : ""}`;
}

export function objectHash(obj: unknown) {
    return md5(`${JSON.stringify(obj)}`);
}

export function dottedToField(path: string) {
    const parts = path.split(".").length;

    if (parts === 1) {
        return path;
    }

    return path.replaceAll(".", "{") + "}".repeat(parts - 1);
}
