function determineEnvironment() {
    if (window.location.hostname.endsWith("stackables.prangli.xyz")) {
        return "local";
    }
    return "production";
}

export const environment = determineEnvironment();

export const languages: Record<string, string> = {
    en: "English",
    et: "Eesti",
};
