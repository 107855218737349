import { loadFsModule } from "./utils.js";

export async function loadSecretsFromDisk() {
    const candidates = [
        (e: string) => `/secrets/tls.${e}`,
        (e: string) => `/workspaces/monocore/.configs.live/server.${e}`,
    ];
    const fs = await loadFsModule();

    if (!fs) {
        return;
    }

    for (const builder of candidates) {
        const keyFile = builder("key");
        const crtFile = builder("crt");

        try {
            const files = await Promise.all([fs.readFile(keyFile), fs.readFile(crtFile)]);

            return {
                key: files[0].toString(),
                crt: files[1].toString(),
            };
        } catch {
            // pass and take next
        }
    }
}
