export type WrapTypemap = {
    string: string;
    number: number;
    object: Record<string, unknown>;
    array: Array<unknown>;
    bytes: Uint8Array;
};
export type WrapTypename = keyof WrapTypemap;

function toJsonEncoded(data: unknown): Uint8Array {
    return new TextEncoder().encode(JSON.stringify(data));
}

function fromJsonEncoded(data: Uint8Array): unknown {
    return JSON.parse(new TextDecoder().decode(data));
}

export function coerceTypeToUint8Array(data: WrapTypemap[WrapTypename]): {
    data: Uint8Array;
    type: WrapTypename;
} {
    if (data instanceof Uint8Array) {
        return { data, type: "bytes" };
    }

    if (Array.isArray(data)) {
        return { data: toJsonEncoded(data), type: "array" };
    }

    if (typeof data === "object") {
        return { data: toJsonEncoded(data), type: "object" };
    }

    if (typeof data === "string") {
        return { data: toJsonEncoded(data), type: "string" };
    }

    if (typeof data === "number") {
        return { data: toJsonEncoded(data), type: "number" };
    }

    throw new Error("Unsupported type");
}

export function coerceUint8ArrayToType<T extends WrapTypename>(data: Uint8Array, type: T): WrapTypemap[T] {
    if (type === "bytes") {
        return data as WrapTypemap[T];
    }
    return fromJsonEncoded(data) as WrapTypemap[T];
}
