import { TemplateComponentFallback } from "@/components/infra/mustache";
import { SchemaHelper } from "@/components/project/SchemaHelper";
import { useSchemaHelper } from "@/components/project/useSchemaHelper";
import { dottedToField } from "@/shared/forms/core/utils";
import { Search } from "lucide-react";
import { useMemo } from "react";
import { useQuery } from "urql";

interface EntityDisplayProps {
    entity: string;
    id?: string;
    schema: SchemaHelper;
}

export function EntityDisplay({ entity, id, schema }: EntityDisplayProps) {
    const { value, loading } = useSchemaHelper(schema, "fetchTypeHints", entity);
    const selectedFields = value?.listFieldsMobile ?? [];
    const [data] = useQuery({
        query: `
            query FetchEntity($id: ID!) {
                response: ${entity} {
                    fetch(by: { id: $id }) {
                        id
                        ${selectedFields.map(dottedToField).join("\n") ?? ""}
                    }
                }
            }
        `,
        variables: { id },
        pause: !id || loading,
        context: useMemo(() => ({ suspense: false }), []),
    });

    if (data.fetching) {
        return null;
    }

    return (
        <>
            <Search className="h-4 w-4 opacity-50 mr-3" />
            <span className="w-full text-sm font-normal text-start">
                <TemplateComponentFallback templates={value?.displayTemplates} data={data.data?.response?.fetch} />
            </span>
        </>
    );
}
