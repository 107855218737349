import { AuthContext, type AuthContextInterface } from "@/components/AuthContext";
import { useContext } from "react";

export function useAuthContext(): AuthContextInterface {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("AuthContext not found");
    }

    return context;
}
