/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { decrypt, LiveConfig, pki } from "../index.js";
import { TemplateSource } from "../TemplateSource.js";

async function _loadConfigFromServer(id: string | undefined, secrets: { crt: string; key: string }) {
    if (!id) {
        throw new Error("Application id not present");
    }

    // try to load the configufration
    const response = await fetch("http://configs-live.stackables.svc.cluster.local:8000/download", {
        method: "POST",
        body: JSON.stringify({
            application: id,
            identity: secrets.crt,
        }),
    });

    if (!response.ok) {
        console.error("Fetch Error:", await response.text());
        return {};
    }

    const responseData = await response.json();

    const keyStored = secrets.key;
    const recipient = await pki.importPrivateKey(keyStored);
    const template = (await decrypt(responseData.template, recipient.privateKey)) as string;
    const source = new TemplateSource(template);

    const decryptedEntries = Object.entries(responseData.variables as Record<string, any>).map(async ([k, v]) => {
        return [k, await decrypt(v, recipient.privateKey)] as const;
    });
    const decrypted = Object.fromEntries(await Promise.all(decryptedEntries));

    const final = source.build(decrypted);

    const finalParsed = JSON.parse(final);

    return finalParsed;
}

export async function loadConfigFromServer(caller: LiveConfig) {
    const secrets = await caller.certs;

    if (!secrets || caller.id?.includes("live")) {
        return;
    }

    const infiniteloop = async () => {
        try {
            const finalParsed = await _loadConfigFromServer(caller.id, secrets);
            caller.sections = finalParsed;
            console.info("Config fetch OK");
        } catch (error) {
            console.error("Config fetch ERROR", error);
        } finally {
            const timer: any = setTimeout(
                () => {
                    void infiniteloop();
                },
                15 * 60 * 1000
            );

            if (typeof timer === "object") {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                timer.unref();
            }
        }
    };

    return infiniteloop();
}
