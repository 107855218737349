import { LiveConfig, z } from "configs.live";

const config = new LiveConfig("cloud-stackables-dev");

export const settings = config.section(
    "settings",
    z.object({
        enabledProjectTypes: z.string().array().default(["s"]),
    })
);
