import type { DialogComponentProps } from "@/components/DialogContextProvider";
import { UniversalActions } from "@/components/DialogOrDrawer";
import { Button } from "@/components/ui/button";
import { settings } from "@/config";
import { useDataAccountProjects } from "@/hooks/useDataAccountProjects";
import { useDataUserAccounts } from "@/hooks/useDataUserAccounts";
import { useMutation } from "@/hooks/useMutationDialog";
import type { FormElementDefinition } from "@/shared/forms";
import { EntityForm } from "@/shared/forms/EntityForm";
import { graphql } from "gql.tada";
import { useForm } from "react-hook-form";
import { AlertFromError } from "../ui/addon/alert";

const FORM_FIELDS: FormElementDefinition = {
    type: "Form",
    elements: [
        {
            type: "String",
            label: "Name",
            scope: ["name"],
            required: true,
            description: "Human readable name for the environment.",
            options: {
                validate: [
                    {
                        required: true,
                        message: "Name is required",
                    },
                    {
                        pattern: "^[a-zA-Z0-9-_ @#()',.]+$",
                        message: "Name should not contain special characters",
                    },
                ],
            },
        },
        {
            type: "String",
            label: "Lookup",
            scope: ["lookup"],
            required: true,
            description: "Short stable identifier for the environment. Needs to be unique for the account.",
            options: {
                controller: {
                    ShadowInput: {
                        shadow: ["name"],
                        transform: "slug",
                    },
                },
                validate: [
                    {
                        pattern: "^[a-z0-9-_]+$",
                        message: "Use only lowercase letters, numbers, hyphens, and underscores",
                    },
                ],
            },
        },
        {
            type: "String",
            label: "Project type",
            scope: ["type"],
            options: {
                oneOf: [
                    {
                        label: "Data",
                        value: "d",
                    },
                    {
                        label: "Config",
                        value: "c",
                    },
                    {
                        label: "Egress",
                        value: "e",
                    },
                    {
                        label: "Survey",
                        value: "s",
                    },
                ].filter((f) => settings.enabledProjectTypes.includes(f.value)),
                defaultValue: settings.enabledProjectTypes[0],
                validate: [
                    {
                        oneOf: true,
                        message: "Invalid project type",
                    },
                ],
                invisible: settings.enabledProjectTypes.length === 1,
            },
        },
    ],
};

const CreateProject = graphql.persisted(
    "sha256:2b4f1aaa677c2523c19812b2a6ffa2900bd01adff59a6f5739f683c013a9879e",
    graphql(`
        mutation CreateProject2($lookup: String!, $name: String!, $type: ProjectType!) {
            createProject(data: { slug: $lookup, name: $name, type: $type }) {
                id
            }
        }
    `)
);

export function CreateProjectDialog(props: DialogComponentProps<undefined, string>) {
    const [createProjectData, createProject] = useMutation(CreateProject);
    const [, refreshDataUserAccounts] = useDataUserAccounts();
    const [, refreshDataAccountProjects] = useDataAccountProjects();
    const { control, handleSubmit } = useForm();

    const onSubmit = async function (d: unknown) {
        const response = await createProject(d as any);
        if (response?.createProject.id) {
            refreshDataUserAccounts({ requestPolicy: "network-only" });
            refreshDataAccountProjects({ requestPolicy: "network-only" });
            props.onSubmit(response.createProject.id);
        }
    };

    return (
        <>
            <AlertFromError error={createProjectData.error} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <EntityForm control={control} element={FORM_FIELDS} canvas="Dialog" />
                <div className="h-4" />
                <UniversalActions>
                    <Button type="submit">Create project</Button>
                </UniversalActions>
            </form>
        </>
    );
}

CreateProjectDialog.title = "Create project";
