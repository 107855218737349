import type { ComponentRegister, NextProps } from "@/shared/forms";
import { Next } from "@/shared/forms/core/Next";

function UnGroupCanvas(props: NextProps & { title?: string }) {
    return (
        <>
            {props.element.elements?.map((element, i) => (
                <Next
                    key={`element-${i}`}
                    control={props.control}
                    element={element}
                    canvas={[...props.canvas, "Grid"]}
                    schema={props.schema}
                />
            ))}
        </>
    );
}

export default {
    name: "UnGroup",
    Component: UnGroupCanvas,
    rules: {
        rank: ({ element, canvas }) => {
            if (canvas[canvas.length - 1] !== "Divided") return -1;
            return ["Group"].includes(element?.type) ? 1 : -1;
        },
    },
} satisfies ComponentRegister;
