import { graphql } from "gql.tada";
import { useQuery } from "urql";

const UseDataAccountProjects = graphql.persisted(
    "sha256:e049025e3d53df54ecfb2bb5199cbe48490672cad0d86a8ab4eeeb30504c2369",
    graphql(`
        query UseDataAccountProjects2 {
            projects: listProjects {
                id
                name
                lookup: slug
                type
            }
        }
    `)
);

export function useDataAccountProjects() {
    const [response, refetch] = useQuery({
        query: UseDataAccountProjects,
        requestPolicy: "cache-first",
    });

    return [response.fetching ? response.data?.projects : (response.data?.projects ?? []), refetch] as const;
}
