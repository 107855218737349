import {
    type UseDataProjectSchemaData,
    type UseDataProjectSchemaDataEntity,
} from "@/components/project/ProjectSchemaContextProvider";
import type { FormElementDefinition } from "@/shared/forms";
import { snakeCase, upperCaseFirst } from "change-case-all";
import { graphql } from "gql.tada";
import invariant from "invariant";
import type { useClient } from "urql";

export interface ProjectSchemaContextValue {
    schema?: UseDataProjectSchemaData;
    client: ReturnType<typeof useClient>;
}

const SchemaHelper_fetchTypeHints = graphql.persisted(
    "sha256:80f55c2f8c5529621871980e15429a6ef7d281f88a7e9887019893f719fc00ed",
    graphql(`
        query SchemaHelper_fetchTypeHints($type: String!) {
            hintTypeSelections(type: $type) {
                id
                singularName
                pluralName
                displayTemplates
                listFields
                listFieldsMobile
            }
        }
    `)
);

export class SchemaHelper {
    types: Record<string, NonNullable<UseDataProjectSchemaDataEntity["types"]>[number]> = {};

    constructor(private context: ProjectSchemaContextValue) {
        context?.schema?.systems?.forEach((system) => {
            system.types?.forEach((type) => {
                if (type.__typename !== "_SystemTypeExtension") {
                    this.types[type.id] = type;
                }
            });
        });
    }

    get schema() {
        return this.context?.schema;
    }

    async fetchTypeHints(entity: string) {
        const response = await this.context.client
            .query(
                SchemaHelper_fetchTypeHints,
                {
                    type: entity,
                },
                { requestPolicy: "cache-first" }
            )
            .toPromise();
        return (
            response.data?.hintTypeSelections ?? {
                id: entity,
                displayTemplates: null,
                listFields: null,
                listFieldsMobile: null,
                singularName: null,
                pluralName: null,
            }
        );
    }

    addType(type: NonNullable<UseDataProjectSchemaDataEntity["types"]>[number]) {
        if (type.__typename !== "_SystemTypeObject") {
            throw new Error(`Type ${type.id} is not an object`);
        }
        this.types[type.id] = type;
    }

    getType(id: string) {
        return this.types[id];
    }

    findEntityType(id: string) {
        const entity = this.types[id];
        if (!entity) {
            throw new Error(`Entity ${id} not found`);
        }
        if (entity.__typename !== "_SystemTypeObject") {
            throw new Error(`Entity ${id} is not an object (${entity.__typename})`);
        }
        if (!entity.directives?.some((directive) => directive.directive === "entity")) {
            throw new Error(`Entity ${id} is not an entity`);
        }
        return entity;
    }

    findTarget(entity: string, path: string[]) {
        // console.log(entity, path, this.types);
        const start = this.types[entity];
        let current = start;
        let field:
            | {
                  name: string;
                  type: string;
                  required: boolean;
                  array: boolean;
                  description?: string;
              }
            | undefined;

        for (const part of path) {
            if (current.__typename !== "_SystemTypeObject") {
                throw new Error(`Entity ${current.id} is not an object`);
            }
            field = current.fields?.find((field) => field.name === part);
            if (!field) {
                throw new Error(`Field ${part} not found in ${current.id}`);
            }
            current = this.types[field.type];
        }
        return field;
    }

    generateForm(entity: string): FormElementDefinition {
        const type = this.getType(entity);

        invariant(type, `Type ${entity} not found`);
        invariant(type.__typename === "_SystemTypeObject", `Type ${entity} is not an object (${type.__typename})`);

        const fieldLabel = (name: string) =>
            upperCaseFirst(
                snakeCase(name, {
                    delimiter: " ",
                })
            );

        return {
            type: "Form",
            elements: type.fields?.map((field) => {
                return {
                    type: field.type,
                    array: field.array,
                    label: fieldLabel(field.name),
                    required: field.required,
                    scope: [field.name],
                };
            }),
        };
    }
}
