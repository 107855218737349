import { coerceTypeToUint8Array, coerceUint8ArrayToType, WrapTypemap, WrapTypename } from "./coerce.js";
import { convertBase64ToUint8Array, convertUint8ArrayToBase64 } from "./utils.js";

export const METHOD = "AES-256-GCM";

export interface AesEncryptedData<ENC> {
    algorithm: "AES-256-GCM";
    key: ENC;
    iv: ENC;
    ciphertext: ENC;
    tag: ENC;
    type: WrapTypename;
}

export function encode(data: AesEncryptedData<Uint8Array | string>): AesEncryptedData<string> {
    return {
        algorithm: data.algorithm,
        key: convertUint8ArrayToBase64(data.key),
        iv: convertUint8ArrayToBase64(data.iv),
        ciphertext: convertUint8ArrayToBase64(data.ciphertext),
        tag: convertUint8ArrayToBase64(data.tag),
        type: data.type,
    };
}

export async function encrypt(data: WrapTypemap[WrapTypename]): Promise<AesEncryptedData<Uint8Array>> {
    const key = await crypto.subtle.generateKey(
        {
            name: "AES-GCM",
            length: 256,
        },
        true,
        ["encrypt", "decrypt"]
    );
    const keyExport = await crypto.subtle.exportKey("raw", key);

    // const iv = new Uint8Array(12);
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encoded = coerceTypeToUint8Array(data);
    const encrypted = await crypto.subtle.encrypt({ name: "AES-GCM", iv, tagLength: 128 }, key, encoded.data);

    const ciphertext = encrypted.slice(0, encrypted.byteLength - 16);
    const authTag = encrypted.slice(encrypted.byteLength - 16);

    return {
        algorithm: METHOD,
        key: new Uint8Array(keyExport),
        iv: iv,
        ciphertext: new Uint8Array(ciphertext),
        tag: new Uint8Array(authTag),
        type: encoded.type,
    };
}

export async function decrypt(data: AesEncryptedData<string | Uint8Array>) {
    try {
        const ciphertext = convertBase64ToUint8Array(data.ciphertext);
        const tag = convertBase64ToUint8Array(data.tag);
        const key = convertBase64ToUint8Array(data.key);
        const iv = convertBase64ToUint8Array(data.iv);
        const type = data.type;

        const encryptedContent = new Uint8Array(ciphertext.length + tag.length);
        encryptedContent.set(ciphertext, 0);
        encryptedContent.set(tag, ciphertext.length);

        const keyRaw = await crypto.subtle.importKey("raw", key, "AES-GCM", true, ["encrypt", "decrypt"]);

        const decryptedContent = await crypto.subtle.decrypt(
            { name: "AES-GCM", iv, tagLength: 128 },
            keyRaw,
            encryptedContent
        );

        return coerceUint8ArrayToType(new Uint8Array(decryptedContent), type);
    } catch {
        throw new Error(`[${METHOD}] Decrypt failed`);
    }
}
