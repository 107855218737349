import get from "lodash.get";
import Mustache from "mustache";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import TemplateComponent from "react-mustache-template-component";

interface TemplateComponentFallbackProps {
    templates: string[] | null | undefined;
    data: Record<string, unknown>;
}
export function TemplateComponentFallback({ templates, data }: TemplateComponentFallbackProps) {
    const { t } = useTranslation("shared");

    const template = useMemo(() => {
        for (const template of templates ?? []) {
            const toplevelUserVars = Mustache.parse(template)
                .filter((v) => v[0] === "name" || v[0] === "#" || v[0] === "&")
                .map((v) => v[1]);
            // if all toplevel user vars are in data, we can use the template
            if (toplevelUserVars.every((v) => get(data, v) !== null)) {
                return template;
            }
        }
    }, [templates, data]);

    if (!template) {
        return (
            <span className="bg-destructive text-destructive-foreground">
                {t("error.templatenotfound", "Template not found")}
            </span>
        );
    }

    return <TemplateComponent template={template} data={data} />;
}
