import { SchemaHelper } from "@/components/project/SchemaHelper";
import type { ComponentRegister } from "@/shared/forms";
import { EntityLookup } from "@/shared/forms/cell/EntityLookup/EntityLookup";

export default {
    name: "EntityLookup",
    Component: EntityLookup,
    rules: {
        rank: ({ element, schema }) => {
            if (element.array) return -1;
            if (schema instanceof SchemaHelper) {
                const red = schema.getType(element.type);
                if (red && red.__typename === "_SystemTypeObject") {
                    if (red.directives?.some((d) => d.directive === "entity")) {
                        return 10;
                    }
                }
            }
            return -1;
        },
    },
} satisfies ComponentRegister;
