export function convertArrayBufferToBase64(buffer: ArrayBuffer) {
    const bytes = new Uint8Array(buffer);
    return convertUint8ArrayToBase64(bytes);
}

export function convertUint8ArrayToBase64(bytes: Uint8Array | string) {
    if (typeof bytes === "string") {
        return bytes;
    }

    const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join("");
    return btoa(binString);
}

export function convertBase64ToUint8Array(base64: string | Uint8Array): Uint8Array {
    if (typeof base64 !== "string") {
        return base64;
    }

    const binary_string = atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

export function convertBase64ToArrayBuffer(base64: string) {
    const bytes = convertBase64ToUint8Array(base64);
    return bytes.buffer;
}
