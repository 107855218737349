import type { ComponentRegister, NextProps, NextPropsField } from "@/shared/forms";
import { cells, components } from "@/shared/forms/core/register";
import { memo } from "react";

function findNextStep(opts: NextProps & Partial<NextPropsField>) {
    let ranking = -1;
    let selected: ComponentRegister | undefined;

    const selectFrom = opts.field ? cells : components;

    for (const candidate of selectFrom) {
        const rank = candidate.rules.rank?.(opts) ?? 0;

        if (rank > ranking) {
            ranking = rank;
            selected = candidate;
        }
    }

    return selected;
}

export function _Next(props: NextProps & Partial<NextPropsField>) {
    const Next = findNextStep(props);

    if (!Next) {
        return (
            <p className="bg-red-500">
                Unable to find next step {props.canvas.join("/")}:{props.element.type}
                {props.element.array ? "[]" : ""}
            </p>
        );
    }

    return <Next.Component {...props} />;
}

export const Next = memo(_Next);
