import { Input } from "@/components/ui/input";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import { Fingerprint } from "lucide-react";

function IDCell({ field }: NextPropsField) {
    return <Input {...field} disabled icon={<Fingerprint className="w-4 h-4" />} />;
}

export default {
    name: "ID",
    Component: IDCell,
    rules: {
        rank: ({ element }) => {
            if (element?.array || !element?.type) return -1;
            if (["ID"].includes(element?.type)) return 3;
            return -1;
        },
    },
} satisfies ComponentRegister;
