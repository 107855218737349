import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { languages } from "@/lib/env";
import { ChevronDown, GlobeIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

export function LanguageSelector({ dropdown, onChange }: { dropdown?: boolean; onChange?: (v: string) => void }) {
    const { i18n } = useTranslation("shared");

    const handleOnChange = (lng: string) => {
        i18n.changeLanguage(lng);
        onChange?.(lng);
    };

    if (dropdown) {
        return (
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant={"outline"} className="gap-2 font-normal" size={"sm"}>
                        <GlobeIcon className="opacity-50" />
                        {languages[i18n.language]}
                        <ChevronDown className="opacity-50" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mx-6">
                    {Object.keys(languages).map((lng) => (
                        <DropdownMenuCheckboxItem
                            key={lng}
                            onClick={() => handleOnChange(lng)}
                            checked={lng === i18n.language}
                        >
                            {languages[lng]}
                        </DropdownMenuCheckboxItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        );
    }

    return (
        <Select value={i18n.language} onValueChange={(v) => handleOnChange(v)}>
            <SelectTrigger>
                <SelectValue placeholder="..." />
            </SelectTrigger>
            <SelectContent>
                {Object.keys(languages).map((lng) => (
                    <SelectItem key={lng} value={lng}>
                        {languages[lng]}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}
