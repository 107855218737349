/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogOrDrawer } from "@/components/DialogOrDrawer";
import React, { useCallback, useMemo, useState, type PropsWithChildren, type ReactElement } from "react";
import { CombinedError } from "urql";
import { type DialogActions, type DialogComponentProps, type DialogUserInterface } from ".";

interface DialogOpenDefinition {
    dialog: (props: any) => ReactElement;
    opts: DialogUserInterface<unknown>;
    value?: unknown;
    actions?: DialogActions<any>;
}

export interface DialogContextInterface {
    open: (dialog: DialogOpenDefinition) => () => void;
}

export const DialogContext = React.createContext<DialogContextInterface>(
    undefined as unknown as DialogContextInterface
);

interface InternalDialogOpenDefinition {
    dialog: DialogOpenDefinition;
    close: () => void;
}

export function DialogContextProvider(props: PropsWithChildren) {
    const [openDialog, setOpenDialog] = useState<InternalDialogOpenDefinition[]>([]);

    const c = React.useMemo<DialogContextInterface>(() => {
        return {
            open(dialog) {
                const close = () => setOpenDialog((prev) => prev.slice(0, -1));
                setOpenDialog((prev) => [...prev, { dialog, close }]);
                return close;
            },
        };
    }, [setOpenDialog]);

    return (
        <DialogContext.Provider value={c}>
            {props.children}
            <ShowDialogs dialogs={openDialog} />
        </DialogContext.Provider>
    );
}

function ShowDialogs(props: { dialogs: InternalDialogOpenDefinition[] }) {
    return (
        <>
            {props.dialogs.map((dialog, i) => (
                <ShowInDialogOrDrawer key={i} dialog={dialog} depth={props.dialogs.length - i} />
            ))}
        </>
    );
}

export function ShowInDialogOrDrawer(params: { dialog: InternalDialogOpenDefinition; depth: number }) {
    const [error, setError] = useState<CombinedError | Error | null>(null);

    const DialogComponent = params.dialog.dialog.dialog;
    const dialogInput = params.dialog.dialog.value;
    const dialogActions = params.dialog.dialog.actions;
    const dialogOpts = params.dialog.dialog.opts;
    const dialogClose = params.dialog.close;

    const handleOnClose = useCallback(
        (submitted = false) => {
            if (!submitted && dialogActions?.onCancel) {
                dialogActions.onCancel();
            }
            if (dialogActions?.onFinally) {
                dialogActions.onFinally();
            }
            dialogClose();
        },
        [dialogActions, dialogClose]
    );

    const dialogPropsWrapped = useMemo(() => {
        return {
            value: dialogInput,
            onSubmit: async (values: unknown) => {
                if (dialogActions?.onSubmit) {
                    try {
                        await dialogActions.onSubmit(values);
                        handleOnClose(true);
                    } catch (error) {
                        if (error instanceof CombinedError || error instanceof Error) {
                            setError(error);
                        } else {
                            handleOnClose();
                            throw error;
                        }
                    }
                } else {
                    handleOnClose(true);
                }
            },
            onClose: handleOnClose,
            onError: setError,
        } as DialogComponentProps;
    }, [dialogActions, dialogInput, handleOnClose]);

    return (
        <DialogOrDrawer
            open={true}
            title={dialogOpts.title}
            disableAutoclose={dialogOpts.disableAutoclose}
            error={error}
            description={dialogOpts.description}
            onOpenChange={handleOnClose}
        >
            <DialogComponent {...dialogPropsWrapped} />
        </DialogOrDrawer>
    );
}
