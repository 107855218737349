import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import { format } from "date-fns";
import { CalendarIcon, ChevronDown } from "lucide-react";

function DateTime({ field }: NextPropsField) {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="outline" className="px-3">
                    <CalendarIcon className="h-4 w-4 opacity-50 mr-3" />
                    <span className="w-full text-sm font-normal text-start">
                        {field.value ? format(field.value, "PPP") : <span>Pick a date</span>}
                    </span>
                    <ChevronDown className="h-4 w-4 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    );
}

export default {
    name: "DateTime",
    Component: DateTime,
    rules: {
        rank: ({ element }) => {
            if (element?.array || !element?.type) return -1;
            if (["DateTime"].includes(element.type)) return 20;
            return -1;
        },
    },
} satisfies ComponentRegister;
