import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useMemo } from "react";

export function useBreakpoints() {
    const desktop = useMediaQuery("(min-width: 768px)");
    const isTouch = useMediaQuery("(hover: none)");

    const data = useMemo(
        () => ({
            desktop,
            mobile: !desktop,
            isTouch,
        }),
        [desktop, isTouch]
    );

    return data;
}
