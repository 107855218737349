import { useAuthContext } from "@/hooks/useAuthContext";
import { type PropsWithChildren, type ReactNode } from "react";

export function SecureArea(props: PropsWithChildren<{ login: () => ReactNode }>) {
    const auth = useAuthContext();

    if (!auth.isAuthenticated) {
        return props.login();
    }

    return props.children;
}
