import { DialogOrDrawer } from "@/components/DialogOrDrawer";
import { SchemaHelper } from "@/components/project/SchemaHelper";
import { Button } from "@/components/ui/button";
import type { NextPropsField } from "@/shared/forms";
import { EntityDisplay } from "@/shared/forms/cell/EntityLookup/EntityDisplay";
import { EntitySearch } from "@/shared/forms/cell/EntityLookup/EntitySearch";
import invariant from "invariant";
import { ChevronDown } from "lucide-react";
import { useCallback, useState } from "react";

export function EntityLookup({ field, schema, element }: NextPropsField) {
    invariant(schema instanceof SchemaHelper, "SchemaAware requires a SchemaHelper");
    const target = schema.getType(element.type);
    invariant(target?.__typename === "_SystemTypeObject", "SchemaAware requires a SystemTypeEnum");

    const [open, setOpen] = useState(false);

    const handleOnSelect = useCallback(
        (v: string) => {
            setOpen(false);
            field.onChange({ id: v });
        },
        [field]
    );

    return (
        <>
            <Button variant="outline" onClick={() => setOpen(!open)} className="px-3">
                <EntityDisplay entity={element.type} id={field.value?.id} schema={schema} />
                <ChevronDown className="h-4 w-4 opacity-50" />
            </Button>
            <DialogOrDrawer open={open} onOpenChange={setOpen} title={`Select ${element.type}`}>
                <EntitySearch entity={element.type} schema={schema} onSelect={handleOnSelect} id={field.value?.id} />
            </DialogOrDrawer>
        </>
    );
}
