import { SchemaHelper } from "@/components/project/SchemaHelper";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import invariant from "invariant";

function SchemaObject(props: NextPropsField) {
    invariant(props.schema instanceof SchemaHelper, "SchemaObject requires a SchemaHelper");
    const target = props.schema.getType(props.element.type);
    invariant(target?.__typename === "_SystemTypeObject", "SchemaObject requires a _SystemTypeObject");

    return <pre>{JSON.stringify(target.fields, null, 2)}</pre>;
}

export default {
    name: "SchemaObject",
    Component: SchemaObject,
    rules: {
        rank: ({ element, schema }) => {
            if (element.array) return -1;
            if (schema instanceof SchemaHelper) {
                const red = schema.getType(element.type);
                if (red && ["_SystemTypeObject"].includes(red.__typename)) {
                    if (!red.directives?.some((d) => d.directive === "entity")) {
                        return 10;
                    }
                }
            }
            return -1;
        },
    },
} satisfies ComponentRegister;
