import { useBreakpoints } from "@/hooks/useBreakpoints";
import * as React from "react";

export function UniversalActions(props: React.PropsWithChildren) {
    const { desktop: isDesktop } = useBreakpoints();

    if (isDesktop) {
        return <div className="flex flex-row justify-end space-x-2">{props.children}</div>;
    }

    return <div className="mt-auto flex flex-col-reverse gap-2">{props.children}</div>;
}
