import type { FormElementDefinition } from "@/shared/forms";
import { Next } from "@/shared/forms/core/Next";
import { type Control } from "react-hook-form";

export interface EntityFormProps {
    control: Control;
    element: FormElementDefinition;
    canvas: string;
    schema?: unknown;
}

export function EntityForm(props: EntityFormProps) {
    const proxy = { ...props, canvas: [props.canvas] };
    return <Next {...proxy} />;
}
