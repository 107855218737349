import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import type { ComponentRegister, NextProps } from "@/shared/forms";
import { Next } from "@/shared/forms/core/Next";

function CardCanvas(props: NextProps & { title?: string }) {
    return (
        <Card>
            {props.title && (
                <CardHeader>
                    <CardTitle>{props.title}</CardTitle>
                </CardHeader>
            )}
            <CardContent>
                <Next
                    control={props.control}
                    element={props.element}
                    canvas={[...props.canvas, "Card"]}
                    schema={props.schema}
                />
            </CardContent>
        </Card>
    );
}

export default {
    name: "Card",
    Component: CardCanvas,
    options: {
        title: {
            type: "string",
        },
    },
    rules: {
        array: false,
        parents: ["", "Card"],
        rank: ({ element, canvas }) => {
            if (canvas[canvas.length - 1] !== "Grid") return -1;
            if (canvas.includes("Divided")) return -1;
            return ["Group"].includes(element?.type) ? 1 : -1;
        },
    },
} satisfies ComponentRegister;
