import { Input } from "@/components/ui/input";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";

function InputCell({ field, element }: NextPropsField) {
    let type: React.HTMLInputTypeAttribute | undefined = undefined;

    switch (element.type) {
        case "Int":
        case "Float":
            type = "number";
            break;
        case "DateTime":
            type = "datetime-local";
            break;
    }

    return <Input {...field} value={field.value ?? ""} id={element.scope?.join(".") + "/input"} type={type} />;
}

export default {
    name: "Input",
    Component: InputCell,
    rules: {
        rank: ({ element }) => {
            if (element?.array || !element?.type) return -1;
            if (["ID", "String", "Int", "DateTime"].includes(element.type)) return 2;
            return -1;
        },
    },
} satisfies ComponentRegister;
