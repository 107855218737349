import { SchemaHelper } from "@/components/project/SchemaHelper";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import { Next } from "@/shared/forms/core/Next";
import invariant from "invariant";

function SchemaEnum(props: NextPropsField) {
    invariant(props.schema instanceof SchemaHelper, "SchemaAware requires a SchemaHelper");
    const target = props.schema.getType(props.element.type);
    invariant(target?.__typename === "_SystemTypeEnum", "SchemaAware requires a SystemTypeEnum");

    return (
        <Next
            {...props}
            element={{
                ...props.element,
                type: "String",
                options: {
                    ...(props.element.options || {}),
                    oneOf: target.values?.map((v) => v.name) || [],
                },
            }}
        />
    );
}

export default {
    name: "SchemaEnum",
    Component: SchemaEnum,
    rules: {
        rank: ({ element, schema }) => {
            if (schema instanceof SchemaHelper) {
                const red = schema.getType(element.type);
                if (red && red.__typename === "_SystemTypeEnum") {
                    return 10;
                }
            }
            return -1;
        },
    },
} satisfies ComponentRegister;
