import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import { lazy } from "react";

const MarkdownEditor = lazy(() => import("./MarkdownEditor")); // default export

function InputCell(props: NextPropsField) {
    return <MarkdownEditor {...props} />;
}

export default {
    name: "Input",
    Component: InputCell,
    rules: {
        rank: ({ element }) => {
            if (element?.array || !element?.type) return -1;
            if (["MarkdownString"].includes(element.type)) return 10;
            return -1;
        },
    },
} satisfies ComponentRegister;
