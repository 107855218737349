import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import type { ComponentRegister, NextPropsField } from "@/shared/forms";
import { objectHash } from "@/shared/forms/core/utils";
import invariant from "invariant";

function AvatarSelect({ field, element }: NextPropsField) {
    invariant(element.options?.oneOf, "EnumController requires options.oneOf to be defined");

    const options = element.options.oneOf.map((optionValue) => {
        const label = typeof optionValue === "string" ? optionValue : optionValue.label;
        const value = typeof optionValue === "string" ? optionValue : optionValue.value;
        const id = objectHash(value);
        return { id, label, value };
    });

    const selected = options.find((option) => option.id === objectHash(field.value));

    return (
        <RadioGroup
            onValueChange={(v) => {
                const final = options.find((option) => option.id === v)?.value;
                field.onChange(final);
            }}
            defaultValue={selected?.id || ""}
            className="grid max-w-md grid-cols-4 gap-2 pt-2"
            disabled={field.disabled}
        >
            {options?.map((optionValue) => (
                <Label className="[&:has([data-state=checked])>div]:border-primary" key={optionValue.id}>
                    <RadioGroupItem value={optionValue.id} className="sr-only" />

                    <div className="items-center rounded-md border-2 border-muted p-4 hover:border-accent justify-center flex cursor-pointer">
                        <Avatar className="h-10 w-10">
                            <AvatarImage src={optionValue.label} />
                        </Avatar>
                    </div>
                </Label>
            ))}
        </RadioGroup>
    );
}

export default {
    name: "AvatarSelect",
    Component: AvatarSelect,
    rules: {
        rank: ({ element }) => {
            return element.options?.controller?.AvatarSelect ? 20 : -1;
        },
    },
} satisfies ComponentRegister;
