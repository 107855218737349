import { graphql } from "gql.tada";
import { useQuery } from "urql";

const UseDataUserAccounts = graphql.persisted(
    "sha256:580c6488c56ba0ce15067fcfa30eb9717fbe5e7dbdd1ee5d2efeda19b28db4b3",
    graphql(`
        query useDataUserAccounts {
            listUserAccounts {
                id
                lookup
                name
                projects {
                    id
                    name
                    lookup
                    type
                }
            }
        }
    `)
);

export function useDataUserAccounts() {
    const [response, refetch] = useQuery({
        query: UseDataUserAccounts,
    });

    return [
        response.fetching ? response.data?.listUserAccounts : (response.data?.listUserAccounts ?? []),
        refetch,
    ] as const;
}
