/* eslint-disable @typescript-eslint/no-explicit-any */
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import type { ComponentRegister, NextProps } from "@/shared/forms";
import { Next } from "@/shared/forms/core/Next";
import { calculateFieldLabel, deriveRules } from "@/shared/forms/core/utils";
import { useController } from "react-hook-form";

function FieldsetController(props: NextProps) {
    if (!props.element.scope) {
        throw new Error("Fieldset requires a scope");
    }

    const { fieldState, field } = useController({
        name: props.element.scope?.join("."),
        control: props.control,
        defaultValue: props.element.options?.defaultValue,
        rules: deriveRules(props.element),
    });

    if (props.element.options?.invisible) {
        return null;
    }

    return (
        <div className="grid w-full items-center gap-1.5">
            <Label
                htmlFor={props.element.scope?.join(".") + "/input"}
                className={cn(fieldState.invalid && "text-error-foreground")}
            >
                {calculateFieldLabel(props.element)}
            </Label>
            <Next {...props} field={field} />
            {props.element.description && <p className="text-sm text-muted-foreground">{props.element.description}</p>}
            {fieldState.invalid && (
                <p className="text-sm font-medium text-error-foreground">
                    {fieldState.error?.message ?? "Incorrect value"}
                </p>
            )}
        </div>
    );
}

export default {
    name: "Fieldset",
    Component: FieldsetController,
    rules: {
        rank: ({ element }) => {
            return element.scope ? 1 : -1;
        },
    },
} satisfies ComponentRegister;
