import { graphql } from "gql.tada";
import { useQuery } from "urql";

const useMe_Query = graphql.persisted(
    "sha256:ced26935a3ea86bcae8a9c74fad0ab32b8bee155aed7a59ab1569aa38b65eb71",
    graphql(`
        query useMe_Query {
            user: fetchUser {
                id
                firstName
                lastName
                primaryEmail
                profilePicture
                preferredLanguage
            }
        }
    `)
);

export function useMe() {
    const [response] = useQuery({
        query: useMe_Query,
    });

    return response?.data?.user;
}
