import introspectedSchema from "@/generated/introspection.json";
import { useAuthContext } from "@/hooks/useAuthContext";
import { authExchange } from "@/lib/urql/authExchange";
import { makeRefreshQueriesExchange } from "@/lib/urql/refreshExchange";
import TTLCache from "@isaacs/ttlcache";
import { cacheExchange, offlineExchange } from "@urql/exchange-graphcache";
import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";
import { persistedExchange } from "@urql/exchange-persisted";
import { refocusExchange } from "@urql/exchange-refocus";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { retryExchange } from "@urql/exchange-retry";
import type { TadaPersistedDocumentNode } from "gql.tada";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Client, createClient, fetchExchange, mapExchange } from "urql";

const timeoutInMinutes = (minutes: number) => minutes * 60 * 1000;
const cache = new TTLCache<
    string,
    { client: Client; refresh: () => void; stats: { requests: number; errors: number } }
>({
    ttl: timeoutInMinutes(60),
});

export function getCachedClient(endpoint: string) {
    return cache.get(endpoint);
}

export function useCachedUrqlClient(endpoint: string) {
    const auth = useAuthContext();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (!auth.isAuthenticated) {
            cache.clear();
        }

        cache.setTTL(endpoint, timeoutInMinutes(60));
        return () => {
            cache.setTTL(endpoint, timeoutInMinutes(10));
        };
    }, [auth, endpoint]);

    return useMemo(() => {
        let cached = cache.get(endpoint);
        if (cached) {
            return cached;
        }

        const storageKey = ["stackables", "v1", ...endpoint.split("/").slice(2)].join("/");
        const dataApi = storageKey.endsWith("/data");
        const branchApi = storageKey.includes(":") && storageKey.split(":")[1]?.split("/")[0] !== "main";

        const { refreshExchange, refresh } = makeRefreshQueriesExchange();

        const stats = { requests: 0, errors: 0 };

        const cacheExchangeImplementation =
            auth.token?.td === true && !dataApi && !branchApi
                ? offlineExchange({
                      schema: introspectedSchema,
                      storage: makeDefaultStorage({
                          idbName: storageKey, // The name of the IndexedDB database
                          maxAge: 7, // The maximum age of the persisted data in days
                      }),
                  })
                : cacheExchange({
                      schema: dataApi ? undefined : introspectedSchema,
                  });

        const exchanges = [
            refreshExchange,
            refocusExchange(),
            requestPolicyExchange({
                // The amount of time in ms that has to go by before upgrading, default is 5 minutes.
                ttl: 60 * 1000, // 1 minute.
            }),
            cacheExchangeImplementation,
            mapExchange({
                onResult(result) {
                    stats.requests++;
                    return result;
                },
                onError(e) {
                    stats.errors++;
                    console.warn(e);
                },
            }),
            retryExchange({
                initialDelayMs: 100,
                maxDelayMs: 10_000,
                randomDelay: true,
                maxNumberAttempts: 2,
                retryIf: (err, operation) => {
                    if (operation.kind === "query") {
                        return err?.networkError !== undefined;
                    }
                    return false;
                },
            }),
            authExchange(auth),
            persistedExchange({
                enforcePersistedQueries: !dataApi,
                enableForMutation: true,
                preferGetForPersistedQueries: true,
                async generateHash(_, document) {
                    return (document as TadaPersistedDocumentNode).documentId;
                },
            }),
            fetchExchange,
        ];

        const client = createClient({
            url: `${endpoint}`,
            exchanges: exchanges,
            suspense: true,
            requestPolicy: "cache-first",
            fetchOptions: {
                headers: {
                    "Accept-Language": i18n.language,
                },
            },
        });

        cached = { client, refresh, stats };

        cache.set(endpoint, cached);

        return cached;
    }, [auth, endpoint, i18n.language]);
}

export function useCachedUrqlRefresh() {
    return {
        refresh: () => {
            for (const { refresh } of cache.values()) {
                refresh();
            }
        },
        stats: (reset = false) => {
            const keys = [...cache.keys()];
            return Object.fromEntries(
                keys.map((k) => {
                    const used = [k, cache.get(k)?.stats];
                    if (reset) {
                        cache.get(k)!.stats.requests = 0;
                        cache.get(k)!.stats.errors = 0;
                    }
                    return used;
                })
            ) as Record<string, { requests: number; errors: number }>;
        },
    };
}
