import type { ComponentRegister } from "@/shared/forms";

function TableCanvas() {
    return <p>Implement me</p>;
}

export default {
    name: "Table",
    Component: TableCanvas,
    options: {
        columns: {
            type: "string",
            array: true,
            required: true,
        },
    },
    rules: {
        array: true,
        parents: ["", "Card"],
        rank: () => -1,
    },
} satisfies ComponentRegister;
