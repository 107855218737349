import { DialogContextProvider, useDialogContext } from "@/components/DialogContextProvider";
import { CreateProjectDialog } from "@/components/project/CreateProjectDialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { UrqlProviderContext } from "@/components/UrqlProviderContext";
import { useBreakpoints } from "@/hooks/useBreakpoints";
import { useDataUserAccounts } from "@/hooks/useDataUserAccounts";
import { usePortalNavigation } from "@/hooks/usePortalNavigation";
import { cn } from "@/lib/utils";
import { useNavigate } from "@/router";
import { Check, Plus } from "lucide-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkRaw, useParams } from "react-router";
import { useDebouncedCallback } from "use-debounce";

export function ProjectSelectorDialog() {
    const params = useParams();
    const naviggate = useNavigate();
    const { isTouch } = useBreakpoints();
    const [accounts] = useDataUserAccounts();
    const selectedAccount = accounts?.find((account) => account.lookup === params.account);
    const [selected, _setSelected] = useState<string | undefined>(selectedAccount?.id ?? "myaccount");
    const setSelected = useDebouncedCallback(
        // function
        (value) => {
            _setSelected(value);
        },
        // delay in ms
        50
    );

    const handleClick = useCallback(
        (account: { id: string; lookup: string }) => {
            if (isTouch) {
                if (selected === account.id) {
                    naviggate("/:account", { params: { account: account.lookup } });
                } else {
                    setSelected(account.id);
                }
            } else {
                naviggate("/:account", { params: { account: account.lookup } });
            }
        },
        [isTouch, naviggate, selected, setSelected]
    );

    return (
        <div className="flex flex-row w-full space-x-2 p-2 md:w-[500px]">
            <div className="flex flex-col w-full md:w-[250px]">
                {accounts?.map((account) => (
                    <Button
                        variant="ghost"
                        className={cn(
                            "justify-start rounded-sm",
                            selected === account.id ? "bg-muted" : "",
                            account.lookup === params.account ? "font-bold" : ""
                        )}
                        key={account.id}
                        onMouseOverCapture={() => setSelected(account.id)}
                        onMouseOutCapture={() => setSelected.cancel()}
                        onClickCapture={() => handleClick(account)}
                    >
                        <span className="flex flex-grow">{account.name}</span>
                        {account.lookup === params.account && <Check className="w-4 h-4 text-muted-foreground" />}
                    </Button>
                ))}
            </div>

            <Separator orientation="vertical" className="h-auto" />

            {selected && <ProjectSelectorContext accountId={selected} />}
        </div>
    );
}

function ProjectSelectorContext(props: { accountId: string }) {
    return (
        <UrqlProviderContext url={`/graphql-new/${props.accountId}`}>
            <DialogContextProvider>
                <ProjectSelectorAccount {...props} />
            </DialogContextProvider>
        </UrqlProviderContext>
    );
}

function ProjectSelectorAccount(props: { accountId: string }) {
    const { t } = useTranslation("shared");

    const [accounts, refresh] = useDataUserAccounts();
    const account = accounts?.find((account) => account.id === props.accountId);
    const params = usePortalNavigation();

    const createProjectDialog = useDialogContext(CreateProjectDialog, {
        title: t("header.createProjectDialog.title", "Create project"),
    });

    return (
        <div className="flex flex-col flex-grow">
            <ScrollArea className="flex-grow h-[0px]">
                <div className="flex flex-col">
                    {account?.projects?.map((project) => (
                        <Button
                            variant="ghost"
                            className={cn(
                                "justify-start rounded-sm",
                                account.lookup === params.account && project.lookup === params.project
                                    ? "font-bold"
                                    : ""
                            )}
                            key={project.id}
                            asChild
                        >
                            <LinkRaw to={params.changeAccount(account.lookup, project.type, project.lookup)}>
                                <span className="flex flex-grow">{project.name}</span>
                                {account.lookup === params.account && project.lookup === params.project && (
                                    <Check className="w-4 h-4 text-muted-foreground" />
                                )}
                            </LinkRaw>
                        </Button>
                    ))}
                </div>
            </ScrollArea>
            <Separator className="my-2" />
            <Button
                variant="ghost"
                className="justify-start rounded-sm"
                onClick={() =>
                    createProjectDialog.show(
                        {},
                        {
                            onFinally: () => {
                                refresh({ requestPolicy: "network-only" });
                            },
                        }
                    )
                }
            >
                <Plus className="w-4 h-4" /> {t("header.action.addproject", "Add new workspace")}
            </Button>
        </div>
    );
}
