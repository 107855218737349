import type { ComponentRegister } from "@/shared/forms";
import AvatarSelect from "@/shared/forms/cell/AvatarSelect";
import DateTime from "@/shared/forms/cell/DateTime";
import EntityLookup from "@/shared/forms/cell/EntityLookup";
import JSONCell from "@/shared/forms/cell/JSONCell";
import MarkdownString from "@/shared/forms/cell/MarkdownString";
import SchemaEnum from "@/shared/forms/cell/SchemaEnum";
import SchemaObject from "@/shared/forms/cell/SchemaObject";
import SchemaObjectProvider from "@/shared/forms/cell/SchemaObjectProvider";
import Card from "../canvas/Card";
import Divided from "../canvas/Divided";
import Grid from "../canvas/Grid";
import Table from "../canvas/Table";
import UnGroup from "../canvas/UnGroup";
import Checkbox from "../cell/Checkbox";
import Id from "../cell/Id";
import Input from "../cell/Input";
import Select from "../cell/Select";
import ShadowInput from "../cell/ShadowInput";
import Fieldset from "../controller/Fieldset";

export const components: ComponentRegister[] = [Card, Grid, Table, Divided, UnGroup, Fieldset];
export const cells: ComponentRegister[] = [
    Checkbox,
    Id,
    Input,
    Select,
    ShadowInput,
    SchemaEnum,
    EntityLookup,
    SchemaObject,
    SchemaObjectProvider,
    DateTime,
    JSONCell,
    AvatarSelect,
    MarkdownString,
];
