/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */

const REPLACE_OPEN = /^(.*?)\$\{([^}]*)}(.*)/ms;

function tokenize(source: string): (string | TemplateVariable)[] {
    const start = source.match(REPLACE_OPEN);
    if (start) {
        const [, token, variable, rest] = start;
        return [token, new TemplateVariable(variable), ...tokenize(rest)];
    }

    return [source];
}

export class TemplateVariable {
    public name: string;
    public defaultValue?: string;

    constructor(source: string) {
        const [name, defaultValue] = source.split(":-");

        this.name = name;
        this.defaultValue = defaultValue;
    }

    build(value?: any): string {
        if (typeof value?.[this.name] === "string") {
            return value[this.name] as string;
        }

        if (this.defaultValue) {
            return this.defaultValue;
        }

        throw new Error(`Missing variable ${this.name}`);
    }
}

export class TemplateSource {
    tokens: (string | TemplateVariable)[];

    constructor(source: string) {
        this.tokens = tokenize(source);
    }

    build(env: any): string {
        return this.tokens
            .map((token) => {
                if (token instanceof TemplateVariable) {
                    return token.build(env);
                }
                return token;
            })
            .join("");
    }
}
