import { Certificate, PrivateKeyInfo, PublicKeyInfo, RSAPrivateKey, RSAPublicKey } from "pkijs";
import { Convert } from "pvtsutils";

export function decodePEM(pem: string, tag = "[A-Z0-9 ]+"): ArrayBuffer[] {
    const pattern = new RegExp(`-{5}BEGIN ${tag}-{5}([a-zA-Z0-9=+\\/\\n\\r]+)-{5}END ${tag}-{5}`, "g");

    const res: ArrayBuffer[] = [];
    let matches: RegExpExecArray | null = null;

    while ((matches = pattern.exec(pem))) {
        const base64 = matches[1].replace(/\r/g, "").replace(/\n/g, "");
        res.push(Convert.FromBase64(base64));
    }

    return res;
}

export interface CLPublicKey {
    id: string;
    publicKey: CryptoKey;
}

export interface CLCert extends CLPublicKey {
    subject: {
        [k: string]: string;
    };
}

export type CLCertImport = CLPublicKey | CLCert;

async function _importCertificate(ber: ArrayBuffer): Promise<CLCert> {
    const cert = Certificate.fromBER(ber);

    const parsedKey = cert.subjectPublicKeyInfo.parsedKey;

    if (!(parsedKey instanceof RSAPublicKey)) {
        throw new Error("Only RSA keys are supported");
    }

    const p = await crypto.subtle.digest("SHA-256", parsedKey.modulus.toBER());

    return {
        id: Convert.ToHex(p),
        subject: Object.fromEntries(cert.subject.typesAndValues.map((t) => [t.type, t.value.getValue()])),
        publicKey: await cert.getPublicKey({
            algorithm: {
                algorithm: {
                    name: "RSA-OAEP",
                    hash: "SHA-256",
                },
                usages: ["encrypt"],
            },
        }),
    };
}

export async function importCertificates(pem: string) {
    const ber = decodePEM(pem, "CERTIFICATE");
    return Promise.all(ber.map(_importCertificate));
}

export async function importCertificate(pem: string) {
    const ber = decodePEM(pem);

    if (ber.length !== 1) {
        throw new Error("Load one certificate at a time");
    }

    return _importCertificate(ber[0]);
}

async function _importPublicKey(ber: ArrayBuffer): Promise<CLPublicKey> {
    const keyinfo = PublicKeyInfo.fromBER(ber);
    const parsedKey = keyinfo.parsedKey;

    if (!(parsedKey instanceof RSAPublicKey)) {
        throw new Error("Only RSA keys are supported");
    }

    const p = await crypto.subtle.digest("SHA-256", parsedKey.modulus.toBER());

    return {
        id: Convert.ToHex(p),
        publicKey: await crypto.subtle.importKey(
            "spki",
            ber,
            {
                name: "RSA-OAEP",
                hash: "SHA-256",
            },
            false,
            ["encrypt"]
        ),
    };
}

export async function importPublicKeys(pem: string) {
    // certificate contains public key so we are good here
    let ber = decodePEM(pem, "CERTIFICATE");
    if (ber.length) {
        return Promise.all(ber.map(_importCertificate));
    }

    // if not then lets import here
    ber = decodePEM(pem, "PUBLIC KEY");
    if (ber.length !== 1) {
        throw new Error("pem encoded string needs to contain a public key or a certificate");
    }

    return Promise.all(ber.map(_importPublicKey));
}

export async function importPrivateKey(pem: string) {
    const ber = decodePEM(pem, "PRIVATE KEY");

    if (ber.length !== 1) {
        throw new Error("Load one key at a time");
    }

    const key = PrivateKeyInfo.fromBER(ber[0]);
    const parsedKey = key.parsedKey;

    if (!(parsedKey instanceof RSAPrivateKey)) {
        throw new Error("Only RSA keys are supported");
    }

    const p = await crypto.subtle.digest("SHA-256", parsedKey.modulus.toBER());

    return {
        id: Convert.ToHex(p),
        privateKey: await crypto.subtle.importKey(
            "pkcs8",
            ber[0],
            {
                name: "RSA-OAEP",
                hash: "SHA-256",
            },
            false,
            ["decrypt"]
        ),
    };
}
