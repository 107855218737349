import { FullScreenLoader } from "@/components/FullScreenLoader";
import { LanguageSelector } from "@/components/settings/comp/LanguageSelector";
import { useWhitelabel } from "@/whitelabel/Whitelabel";
import { Suspense, type PropsWithChildren } from "react";

interface FloatingFullscreenDialogProps {
    loadingText?: string;
    loading?: boolean;
    quote?: boolean;
    disableLoader?: boolean;
}

export function FloatingFullscreenDialog(props: PropsWithChildren<FloatingFullscreenDialogProps>) {
    const { Logo } = useWhitelabel();

    return (
        <div className="starback h-screen">
            <div className="flex flex-col h-screen dark:backdrop-invert">
                <div className="p-6 md:p-8 z-10 md:absolute">
                    <Logo variant={props.quote ? "login-quote" : "login"} />
                </div>
                <div className="self-end p-4 z-[100]">
                    <LanguageSelector dropdown />
                </div>
                <div className="w-full h-full content-center z-50 md:p-8 bg-opacity-70">
                    {props.loading ? <FullScreenLoader message={props.loadingText} /> : null}
                    {!props.loading && (
                        <Suspense
                            fallback={props.disableLoader ? null : <FullScreenLoader message={props.loadingText} />}
                        >
                            {props.children}
                        </Suspense>
                    )}
                </div>
            </div>
        </div>
    );
}
